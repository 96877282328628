/* navbar.css */
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #1B2021;
    color: white;
    font-family: "Lato";
    height: 70px;;
    width:auto;
    overflow: hidden;
}

.title{
    position: relative;
    bottom: 19px;
    left: 20px;
    color: #ffffff;
    font-stretch:narrower;
    font-weight: 300;
    font-size: 22px;
    font-style: oblique;
}

.logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo-wrapper a {
    text-decoration: none;
}

.logo-wrapper :hover {
    transition: filter 1s;
    filter: brightness(10);
}

.Logo1 {
    height: 50px;
    width: auto;
    border: solid;
    border-width: 2px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 0px;
    border-color: #fff;
}

.menu-icon {
    display: none;
    flex-direction: column;
    cursor: pointer;
}

.menu-icon .bar {
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 4px 0;
    transition: all 0.3s ease;
}

.menu-icon .bar.open:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
}

.menu-icon .bar.open:nth-child(2) {
    opacity: 0;
}

.menu-icon .bar.open:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
}

.links ul {
    list-style: none;
    display: flex;
    gap: 30px;
    padding: 0;
    margin: 0;
    margin-right: 20px;
    
}

.links li {
    list-style: none;
}

.links a {
    color: #ffffff;
    text-decoration: none;
    font-size: 18px;
    transition: color 0.3s ease-in-out;
}

.links a:hover {
    color: #F0D2D1; /* Change this to your desired hover color */
}

/* Responsive styles */
@media screen and (max-width: 768px) {
    .menu-icon {
        display: flex;
    }

    .links {
        display: none;
        flex-direction: column;
        position: absolute;
        text-align: center;
        top: 60px;
        left: 0;
        background-color: #1B2021;
        width: 100%;
        
        box-sizing: border-box;
        
    }

    .links.open {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        padding-left: 40px;
        margin-top: 20px;
        overflow-x: hidden;
        z-index: 10;
    }

    .links ul {
        flex-direction: column;
        gap: 10px;
        width: 100%;
        text-align: center;
        
    }
}
