.servContainer{
    background-color: rgb(211, 211, 211);;
    width: auto;
    height:auto;
    display: flex;
    flex-direction: column;
}

.servContainer h1{
    margin-top: 50px;
    margin-bottom: 20px;

    font-size: 2.5em;
    font-weight: 400;
}

.servContainer b{
    margin-bottom: 0;
    font-size: 1.1em;
    font-weight: 400;
    color: #000000;
}

.servContainer a{
    margin: 30px;
}

.packages{
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 100px;
    flex-direction: column;
    text-align: center;
    margin: 20px;
    margin-bottom: 100px;
}

.package h3{
    font-size: 1.2em;
    font-weight: 600;
}

.package-0{
    background-color: #fff;;
    color: #000000;
}

.package-1{
    background-color: #c2c2c2;;
    color: #000000;
}

.package-2 {
    background-color: #1B2021;
}

.package-2 h3, .package-2 ul{
    color: #e1cdec;
}

.package-3{
    background-color: #e1cdec;
}

.listing{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    justify-content: center;
}

.listing div{
    border: solid;
    border-width: 1px;
    width: 225px;
    min-height: 460px;
    padding: 30px;
    box-shadow: 0 4px 8px rgba(27, 32, 33, 0.5); /* Box shadow with a slight blur */
}

.package{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    text-align: center;
    margin:0;
    padding: 0;
    left: 0;
}

.package h2{
    font-weight: 500;
}

.package ul{
    list-style-type: none; /* Remove the default bullet points */
    padding: 0; /* Remove the default padding */
    text-align: center; /* Center the list items horizontally */
}

.package li {
    display: flex; /* Make the list items inline */
    justify-content: center;
    margin: 20px 10px; /* Add some horizontal margin for spacing */
}

.faqWrap{
    display: flex;
    width:65%;
    text-align: left;
    margin: auto;
    justify-content: center;
    padding: 30px;
    background-color:#fff;
    margin-bottom: 40px;
    border: solid;
    border-width: 1px;
    box-shadow: 0 4px 8px rgba(27, 32, 33, 0.5); /* Box shadow with a slight blur */
}

.serviceToContact{
    margin-bottom: 50px !important;
}

.faqWrap h2{
    font-size: 2.5em;
    font-weight: 400;
    padding-bottom: 20px;
}

.faqWrap div{
    font-size: 1.2em;
    font-weight: 200;
    background-color: inherit;
    color: #000000;
}