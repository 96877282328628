.about-container {
  width: 100%;
  min-height: 70vh;
  margin: 0 auto;
  background-color: rgb(1,15,28);
  color: white;
  padding-top: 200px;
  overflow: hidden;
  background-size: cover;
}

.about-container a {
  position: relative;
  top: 50px;
  color: white;
  font-size: 1.1em;
}

.about-slide {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  right:0.9%;
}


.about-desc {
  width: 60%;
  margin: auto;
  padding-top: 0px;
}

.about-desc p {
  font-size: 1.3em;
  font-weight: 100;
}

.about-image img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 20px;
}

.about-title {
  font-size: 24px;
  color: #fff;
  margin-bottom: 10px;
}

.about-content p {
  font-size: 30px;
  color: #fff;
  margin-bottom: 8px;
}

.slick-slider {
  width: 90%;
  margin: auto;
}

.slick-prev,
.slick-next {
  width: 40px;
  height: 40px;
  z-index: 1;
}

.slick-prev:before,
.slick-next:before {
  font-size: 40px;
  color: white ; /* Arrow color */
}

.slick-dots {
  position: absolute;
  left: 0px;
}

.slick-dots li button:before {
  color: white !important; /* Dot color */
}

.slick-dots li.slick-active button:before {
  color: #fff !important; /* Active dot color */
}

/* Responsive Design for Mobile Devices */
@media (max-width: 768px) {
  .about-slide {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    right:5%;
  }

  .about-container {
    padding-top: 100px;
  }

  .about-container a {
    font-size: 1em;
    top: 20px;
  }

  .about-desc {
    width: 80%;
  }

  .about-desc p {
    font-size: 1.1em;
    position: relative;
  }

  .about-title {
    font-size: 20px;
  }

  .about-content p {
    font-size: 24px;
  }

  .slick-prev,
  .slick-next {
    width: 30px;
    height: 30px;
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 30px;
  }
}


