/* portfolio.css */

.portfolioHead {
  position: relative;
  max-width: 90%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #000;
  margin: auto;
  padding: 20px;
  text-align: center;
}

.ShinyLogo {
  margin: auto;
  left: 0;
  position: relative;
  top: 20px;
}

.slick-dots {
  position: relative;
  left: 10px;
}

.portfolio {
  background-color: #000;
  width: 100%;
  min-height: 100vh; /* Ensure it covers at least the full viewport height */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px; /* Adjust padding as necessary */
  overflow: hidden;
  padding-top: 25px;
}

.portfolio h1 {
  background-color: #000;
  color: white;
  margin: 0;
  padding-top: 25px;
  font-size: 2.5rem;
  font-weight: 300;
}

.portfolio a {
  color: white;
  position: relative;
  margin-top: 20px; /* Adjust margin as necessary */
}

.port-slider {
  width: 80%;
  height: auto;
  display: flex !important;
  flex-direction: row !important;
  text-align: center;
  padding-bottom: 0px;
  overflow: hidden;
  position: relative;
}

.port-slide {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
}

.sliderPort {
  padding: 40px;
}

.port-desc b {
  font-weight: 200;
  font-size: 1.2em;
  color: #ffffff;
}

.port-img img {
  max-width: 100%;
  height: auto;
  margin: 20px 0;
}

.slick-prev:before,
.slick-next:before {
  color: #333; /* Adjust the color of the arrows */
}

.slick-dots li button:before {
  font-size: 12px;
  color: #333; /* Adjust the color of the dots */
}

.slick-dots li.slick-active button:before {
  color: #000; /* Adjust the color of the active dot */
}

@media (max-width: 768px) {
  .portfolioHead {
    margin: 0;
    padding: 20px;
  }

  .port-img{
    width: 100%;
  }

  .ShinyLogo {
    width: 70%;
    min-width: 200px;
  }

  .port-slider {
    padding-top: 0px;
    height: auto;
    flex-direction: column-reverse;
    width:100%;
  }

  .portfolio a {
    margin-top: 0; /* Reset the margin top from the main style */
  }
}

@media (max-width: 600px) {
  .portfolioHead {
    padding: 10px;
  }

  .ShinyLogo {
    width: 60%;
    min-width: 150px;
  }

  .port-slider {
    padding-top: 0;
    height: auto;
    flex-direction: column-reverse;
  }

  .portfolio h1 {
    font-size: 2rem;
    padding-top: 15px;
  }

  .portfolio a {
    margin-top: 0; /* Reset the margin top from the main style */
  }

  .port-desc{
    font-size: 0.9rem;
  }
}
