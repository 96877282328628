:root {
  --primary: #000000;
  --secondary: #38ef7d;
  --white: #fff;
  --gray: #000000;
}

.domainWrap {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Center content vertically */
  /*background-color: #61E8E1;*/
  background-color: white;
  padding: 100px;
  gap: 50px;
}

.domainWrap h2 {
  font-size: 30px;
  font-weight: 300;
  position: relative;
  top: 0px;
  color: #000000;
}

.form__group {
  display: flex;
  flex-direction: row;
  position: relative;
  padding: 15px 0 0;
  margin-top: 20px;
  width: 100%;
  
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid var(--gray);
  outline: 0;
  font-size: 1.3rem;
  color: #2b706d;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
  position: relative;
  bottom: 20px;
}

.form__field::placeholder {
  color: transparent;
}

.form__field:placeholder-shown ~ .form__label {
  font-size: 1.3rem;
  cursor: text;
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: #2b706d;
}

.form__field:focus {
  padding-bottom: 6px;
  font-weight: 700;
  border-width: 3px;
  border-image: linear-gradient(to right, black, #68fff7);
  border-image-slice: 1;
}

.form__field:focus ~ .form__label {
  top: -25px;
  font-size: 1rem;
  color: #000000;
  font-weight: 700;
}

.form__field:required,
.form__field:invalid {
  box-shadow: none;
}

.form__group b {
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-size: 1.5rem;
  

}

.search {
  background-color: var(--primary);
  border: none;
  color: white;
  padding: 10px 20px;
  margin: 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  height: 40px;
  transition: background 0.3s, transform 0.3s;
  cursor: pointer;
}

.search:hover {
  background: linear-gradient(to right, black, #68fff7);
  transform: scale(1.05);
}

.search:active {
  transform: scale(0.95);
}

.search:disabled {
  background: var(--gray);
  cursor: not-allowed;
}

#result {
  position: relative;
  top: 0px;
  width: 200px;
  display: flex;
}

#result b {
  border: solid;
  border-width: 2px;
  border-color: black;
  padding: 5px;
  background-color: #F0D2D1;
  font-weight: 100;
}


@media screen and (max-width: 1000px) {
  .form__group {
    flex-direction: column;
    width: 100%;
  }

  .domainWrap {
    gap: 40px;
    flex-direction: column;
    padding: 40px;
  }

  .search {
    margin-top: 60px;
    min-width: 300px;
  }

  .form__field:placeholder-shown ~ .form__label {
    font-size: 1.3rem;
    top: 0px;
  }

  #result b {
    border: solid;
    border-width: 2px;
    border-color: black;
    padding: 5px;
    background-color: #F0D2D1;
    font-weight: 100;
  }
  
}

@media screen and (max-width: 600px) {
  .domainWrap {
    padding: 20px;
  }

  .form__field {
    font-size: 1rem;
    padding: 5px 0;
  }

  .form__field:placeholder-shown ~ .form__label {
    font-size: 1rem;
    top: 0px;
  }

  .form__label {
    font-size: 0.8rem;
  }

  .search {
    font-size: 14px;
    height: 35px;
    padding: 8px 15px;
  }
}
