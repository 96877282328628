/*https://coolors.co/1b2021-61e8e1-51513d-c589e8-f0d2d1*/

.homeWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #000000;
    overflow-x: hidden; /* Prevent horizontal scrolling */
    background-image: url("/public/flower3Rep.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width:auto;
    height: calc(100vh - 70px); /* Set height to ensure the container is visible */
    overflow-y: hidden;
}

.shader{
    width:100vw;
    height: calc(100vh - 70px);
    background-color:  rgba(0,0,0,0.7);
    display: flex;
    align-items: left;
    justify-content: left;
    flex-direction: column;
    color: white;
}

.homeTitle{
    position: relative;
    left:-300px;
    top:300px;
}

.homeHeader{
    font-size: 60px;
    font-weight: 400;
}

.homeTitle a{
    margin-right: 30px;
    padding: 10px;
    border: solid;
    border-width: 2px;
    border-radius: 5px;
    text-decoration: none;
    background-color: white;
    color: black;
}

.homeTitle a:hover{
    color: white;
   background-color: transparent;
 
}

.homeTitle b{
    font-weight: 100;
    font-size: 19px;
}

.gar{
    padding-top: 50px;
    height: auto;
    background-color: #c2c1c1;
    padding-bottom: 50px;;
}

.packs{
    margin-top: 30px;
}

.hoContainer {
    max-width: 60%;
    height: auto;
    margin: 0 auto;
    padding: 40px 20px;
    text-align: center;
    background-color: inherit;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

header h2 {
    font-size: 2.5em;
    font-weight: 400;
    margin-bottom: 40px;
}

.features {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    gap: 20px;
}

.feature {
    border-radius: 8px;
    padding: 20px;
    width: 250px;
    height:auto;
}

.feature h2 {
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 10px;
}

.feature p {
    font-size: 0.9em;
    line-height: 1.5;
}

.get-started {
    display: inline-block;
    margin-top: 40px;
    padding: 15px 30px;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 25px;
    font-size: 1em;
    cursor: pointer;
    text-decoration: none;
}

.get-started:hover {
    background-color: #333;
}

a {
    color: #000;
    text-decoration: underline;
}

a:hover {
    text-decoration: none;
}

@media screen and (max-width: 1000px) {
    .homeWrap{
        background-image: url("/public/flower3Rep.jpg");
    }
    
    .shader {
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 20px;
       
    }

    .homeHeader {
        font-size: 50px;
    }

    .homeTitle {
        position: relative;
        left: 0;
        top: 0;
        text-align: center;
        padding: 30px;
        margin: 0;
    }

    .homeWrap2{
        display: flex;
        gap: 20px;
        flex-direction: column-reverse;
        margin:20px;
    }

    .homeTitle a {
        margin: 10px 0; /* Adjust margin for better spacing on mobile */
        padding: 10px;
        font-size: 16px; /* Adjust font size for better readability */
        width: auto;
    }

    .homeTitle b {
        font-size: 19px;
        margin: 0; /* Ensure no indentation */
        padding: 0; /* Ensure no indentation */
        font-weight: 100;
        font-size: 19px;
       
    }
}