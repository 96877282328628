.submit-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 14px;
  color: #a31ef0;
  background-color: #fff;
  border-width: 1px;
  box-shadow: 0 0 1px rgb(204, 204, 204);
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
  margin-top: 20px;
}

.submit-button:hover {
  box-shadow: 0 0 4px rgb(204, 204, 204);
}

.submit-button:active {
  background-color: #f8f8f8;
}

.submit-button:focus {
  outline: none;
}

/* Optional: Add focus and active states for accessibility */
.submit-button:focus-visible {
  outline: 2px solid #5264AE;
}

.contact {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #61E8E1;
}

.contact h1 {
  font-size: 2.5em;
    font-weight: 400;
}

.contact b{
  font-size: 1.2em;
    font-weight: 200;
}

.container {
  width: 100%; /* Adjusted for responsiveness */
  max-width: 600px;
  margin: 30px auto 0;
  display: block;
  background: #FFF;
  padding: 10px 50px 50px;
}

textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  line-height: 1.5;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none;
  overflow: hidden; /* Hide the scrollbar */
  box-sizing: border-box;
}

.contact-form {
  width: 70%;
  max-width: 500px !important;
  margin: 80px auto;
}

.group {
  position: relative;
  margin-bottom: 45px;
}

input, textarea {
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #757575;
  background-color: #61E8E1;
}

input:focus, textarea:focus {
  outline: none;
}

label {
  color: #000000;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

input:focus ~ label, input:valid ~ label, textarea:focus ~ label, textarea:valid ~ label {
  top: -20px;
  font-size: 14px;
  color: #ffffff;
}

.bar {
  position: relative;
  display: block;
  width: 100%;
}

.bar:before, .bar:after {
  content: '';
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #ffffff;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.bar:before {
  left: 50%;
}

.bar:after {
  right: 50%;
}

input:focus ~ .bar:before, input:focus ~ .bar:after, textarea:focus ~ .bar:before, textarea:focus ~ .bar:after {
  width: 50%;
}

.highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

input:focus ~ .highlight, textarea:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

@-webkit-keyframes inputHighlighter {
  from {
    background: #5264AE;
  }
  to {
    width: 0;
    background: transparent;
  }
}

@-moz-keyframes inputHighlighter {
  from {
    background: #5264AE;
  }
  to {
    width: 0;
    background: transparent;
  }
}

@keyframes inputHighlighter {
  from {
    background: #5264AE;
  }
  to {
    width: 0;
    background: transparent;
  }
}

.dropdown {
  width: auto;
  display: inline-block;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 0 2px rgb(204, 204, 204);
  transition: all 0.5s ease;
  position: relative;
  font-size: 14px;
  color: #474747;
  height: 100%;
  text-align: left;
  margin-bottom: 20px;
}

.dropdown .select {
  cursor: pointer;
  display: block;
  padding: 10px;
  color: #000000;
  background-color: #1b202100;
  text-align: center;
}

.dropdown .select > i {
  font-size: 13px;
  color: #888;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  float: right;
  line-height: 20px;
}

.dropdown:hover {
  box-shadow: 0 0 4px rgb(204, 204, 204);
}

.dropdown:active {
  background-color: #f8f8f8;
}

.dropdown.active:hover, .dropdown.active {
  box-shadow: 0 0 4px rgb(204, 204, 204);
  border-radius: 2px 2px 0 0;
  background-color: #f8f8f8;
}

.dropdown.active .select > i {
  transform: rotate(-90deg);
}

.dropdown .dropdown-menu {
  position: absolute;
  background-color: #fff;
  width: 100%;
  left: 0;
  margin-top: 1px;
  box-shadow: 0 1px 2px rgb(204, 204, 204);
  border-radius: 0 1px 2px 2px;
  overflow: hidden;
  display: none;
  max-height: 144px;
  overflow-y: auto;
  z-index: 9;
}

.dropdown .dropdown-menu li {
  padding: 10px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.dropdown .dropdown-menu {
  padding: 0;
  list-style: none;
}

.dropdown .dropdown-menu li:hover {
  background-color: #f2f2f2;
}

.dropdown .dropdown-menu li:active {
  background-color: #e2e2e2;
}